<template>
  <div style="width: 100%; overflow: hidden; position: relative;" id="parent">
    <div class="left_tab_parent" >
      <div class="left_tab">
        <div
          v-for="(item, index) in tab_list"
          :key="index"
          :class="{ isChoose: isChoose == index }"
          @click="showTab(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="right_content_parent">
        <component :is="componentsList[isChoose]" style="padding-bottom: 50px" v-if="forceUpdate"></component>
    </div>
    <div class="enterprise-footer" style="text-align: center">
      <a style="color: #515a6e" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2024 苏ICP备18024573号-2. All Rights Reserved.</a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";

export default {
  name: "EnterpriseManagementCenter",
  components: {
    AccountManagement: () =>
      import(
        "@/components/science/EnterpriseManagementCenter/AccountManagement.vue"
      ),
    EnterpriseConsultingManagement: () =>
      import(
        "@/components/science/EnterpriseManagementCenter/EnterpriseConsultingManagement.vue"
      ),
    EnterPriseInfo: () =>
      import(
        "@/components/science/EnterpriseManagementCenter/EnterPriseInfo.vue"
      ),
    EnterpriseKanban: () =>
      import(
        "@/components/science/EnterpriseManagementCenter/EnterpriseKanban.vue"
      ),
    PatentOrder: () =>
      import("@/components/science/EnterpriseManagementCenter/PatentOrder.vue"),
    ServiceOrder: () =>
      import(
        "@/components/science/EnterpriseManagementCenter/ServiceOrder.vue"
      ),
  },
  computed: {
    ...mapGetters({ accountType: "userStore/accountType" }),
  },

  mounted() {
    this.isChoose = this.$route.query.id;
    // 逻辑判断 如果不是主账号则不可以看到账号管理
    if (this.accountType != 1) {
      this.tab_list.splice(this.tab_list.length - 2, 1);
    }
    this.changeHeight();
    this.changeBg();
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        if (this.$route.query.id) {
          this.isChoose = this.$route.query.id;
        }
        this.changeBg();
      },
    },
    startTime() {
      let self = this;
      this.endOption = {
        disabledDate(date) {
          if (self.startTime) {
            return date && date.valueOf() < self.startTime;
          }
        },
      };
    },
    endTime() {
      let self = this;
      this.startOption = {
        disabledDate(date) {
          if (self.endTime) {
            return date && date.valueOf() > self.endTime;
          }
        },
      };
    },
  },
  data() {
    return {
      forceUpdate: true,
      componentsList: [
        "EnterpriseKanban",
        "EnterPriseInfo",
        "EnterpriseConsultingManagement",
        "PatentOrder",
        "ServiceOrder",
        "AccountManagement",
      ],
      tab_list: [
        "企业看板",
        "企业信息管理",
        "企业咨询管理",
        "专利订单管理",
        "服务订单管理",
        "账号管理",
      ],
      isChoose: 0,
      tab_height: 0,
    };
  },
  methods: {
    showTab(index) {

      if(index != 6){
        this.forceUpdate = false;
        this.isChoose = index;
        this.$nextTick(() => {
          this.$router.push({
            path: "/enterprise/enterprise-management-center",
            query: {
              id: this.isChoose,
            },
          });
          this.forceUpdate = true;
        });
        scrollTo(0, 0);
      }else{
        window.open("http://seller.lygstrim.com/broker-dist/")
      }

    },
    changeHeight() {
      let that = this;
      // this.$nextTick(() => {
      //   that.tab_height =
      //     Number($(".right_content_parent").height()) >
      //     Number(document.body.clientHeight) - 101
      //       ? $(".right_content_parent").height() + "px"
      //       : Number(document.body.clientHeight) - 101 + "px";
      // });
    },
    changeBg() {
      if(['0', '1'].includes(this.$route.query.id)) {
        document.querySelectorAll('.enterprise-footer,.right_content_parent').forEach(item => {
          item.style.background = '#f4f8ff'
        })
      } else {
        document.querySelectorAll('.enterprise-footer,.right_content_parent').forEach(item => {
          item.style.background = '#fff'
        })
      }
    }
  },
};
</script>

<style scoped lang="scss">
.right_content_parent {
  margin-left: 160px;
  position: relative;
  background: #f4f8ff;
  min-height: calc(100% - 46px);
}

.left_tab_parent {
  float: left;
  // min-width: 160px;
  width: 160px;
  background-color: #D5E7FA;
  height: 100%;
  position: fixed;
  top: 101px;
  .left_tab {
    div {
      height: 61px;
      line-height: 61px;
      font-size: 14px;
      color: #597697;
      padding-left: 20px;
      cursor: pointer;
      border-bottom: 1px solid #BDDBFA;
      &.isChoose {
        color: #fff;
        background-color: #1767E0;
      }
    }
  }
}
.bottom_style {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -110px;
}
</style>
